import React, { useEffect, useState } from 'react'
import { Box, Carousel, Loading, UserLayout } from '../class'
import { GetCategoryByUser, GetNewsByUser } from '../libs/api'

const Welcome = () => {
    const [category, setCategory] = useState([])
    const [randomCategory, setRandomCategory] = useState([])
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)

    const fectData = async () => {
        setCategory(await GetCategoryByUser())
        setNews(await GetNewsByUser())
        setRandomCategory(category.sort(() => 0.5 - Math.random()).slice(0, 3))
        setLoading(false)
    }

    useEffect(() => {
        fectData()
    }, [loading])

    return (
        loading
            ?
            <Loading />
            :
            <UserLayout>
                <Carousel news={news} />
                {
                    randomCategory.map((item, index) => (
                        <Box key={index} category={item} news={item.news} />
                    ))
                }
            </UserLayout>
    )
}

export default Welcome
