import React, { useEffect, useRef, useState } from 'react'
import { AdminHeader, AdminLayout, ErrorBox, Loading } from '../../../class'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCategoryById, updateCategory, VerifyAdmin } from '../../../libs/api'

const DetailCategory = () => {
    const { id } = useParams()
    const [category, setCategory] = useState("")
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState(category.name)
    const [image, setImage] = useState("")
    const [message, setMessage] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
        setName(category.name)
    }, [loading])

    const fetchData = async () => {
        try {
            setCategory(await GetCategoryById(id))
            const response = await VerifyAdmin()
            if (response == "not admin") navigate('/')
            setLoading(false)
        } catch (error) {
            navigate('/')
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        setImage(file)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append("name", name)
        formData.append("image", image)

        if (!name || name.trim() == "") {
            setMessage("Name Field Can't Be Empty")
            return;
        }

        const response = await updateCategory(id, formData, name, image)

        if (response == 'success') {
            navigate("/admin/category")
        } else {
            setMessage(response)
        }
    }

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={`Update ${category.name} Category`} />

                    <div className='mt-4'>
                        <form onSubmit={handleSubmit} encType='multipart/form-data'>
                            <ErrorBox message={message} />
                            <div className='flex flex-col gap-y-4'>
                                <div className='flex flex-col'>
                                    <label htmlFor="name" className='mb-1 text-sm font-medium text-gray-900'>Name</label>
                                    <input type="text" id='name' placeholder='Categoy Name' defaultValue={category.name} onChange={(e) => { setName(e.target.value); console.log(name); }}
                                        className='p-2 text-gray-900 border border-gray-300 rounded-lg 
                                bg-gray-50 text-sm focus:ring-gray-900 focus:border-gray-900' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="image" className='mb-1 text-sm font-medium text-gray-900'>Image</label>
                                    <input type="file" id='image' name='image' onChange={handleFileChange}
                                        className='p-2 text-gray-900 border border-gray-300 rounded-lg duration-300 transition-all
                                bg-gray-50 text-sm focus:ring-gray-900 focus:border-gray-900
                                file:mr-5 file:px-3 file:border-[0px]
                                file:text-sm file:font-medium file:rounded-l-lg
                                file:bg-stone-50 file:text-gray-900
                                hover:file:cursor-pointer hover:file:bg-white'
                                    />
                                </div>
                                <div className='flex items-center justify-end'>
                                    <button type="submit" className='text-white bg-neutral-900 
                                focus:ring-1 focus:outline-none focus:ring-neutral-900
                                font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 shadow-md hover:shadow-xl transition-all duration-300'>Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default DetailCategory
