import React, { useEffect, useState } from 'react'
import { BoxCategory, Loading, UserLayout } from '../class'
import { useParams, useNavigate } from 'react-router-dom'
import { GetCategoryByUserAndId } from '../libs/api'

const CategoryPage = () => {
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState([])
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      setCategory(await GetCategoryByUserAndId(id))
      setLoading(false)
      console.log(category);
      
    } catch (error) {
      navigate('/')
    }
  }

  useEffect(() => {
    fetchData()
  }, [loading, id])

  return (
    loading
      ?
      <Loading />
      :
      <UserLayout>
        <div className='flex justify-between items-center text-neutral-900 pb-2 mb-4 border-b-4 border-neutral-900'>
          <h1 className='sm:text-5xl text-3xl font-medium tracking-wider font-ebGaramond'>{category.name}</h1>
        </div>

        {
          category.news.map((item, index) => (
            <BoxCategory key={index} category={category} news={item} />
          ))
        }
      </UserLayout>
  )
}

export default CategoryPage
