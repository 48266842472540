import React, { useEffect, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { CgCopyright, CgMail } from 'react-icons/cg'
import { PiGithubLogo } from 'react-icons/pi'
import { SiLinkedin } from 'react-icons/si'
import { FiInstagram } from 'react-icons/fi'
import { GetCategoryByUser, GetNewsByUser } from '../libs/api'
import { Link } from 'react-router-dom'

const Footer = () => {
    const [category, setCategory] = useState([])
    const [news, setNews] = useState([])

    const fetchData = async () => {
        setCategory(await GetCategoryByUser((item) => item.sort(() => 0.5 - Math.random())))
        setNews(await GetNewsByUser((item) => item.sort(() => 0.5 - Math.random())))
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <footer>
            <div className='bg-neutral-800 text-white py-12 px-8'>
                <div className='container flex flex-wrap justify-center gap-x-12 gap-y-4 mx-auto'>
                    <div className='px-4 max-w-[500px] font-ebGaramond'>
                        <h3 className='text-lg font-semibold mb-6'>About NewsApp</h3>
                        <p className='text-white/60'>
                            NewsApp is an application where people may see the newest news around the earth. Therefore people can know what is happening
                            in this earth.
                        </p>
                    </div>
                    <div className='px-4 font-ebGaramond'>
                        <h3 className='text-lg font-semibold mb-6'>Categories</h3>
                        <ul className='flex md:flex-col flex-wrap gap-x-4 gap-y-4 text-lg text-white/60'>
                            {
                                category.map((item, index) => index <= 4 && (
                                    <li key={index}>
                                        <Link to={`/category/${item.id}`} className='flex items-center hover:text-white'>
                                            <IoIosArrowForward />
                                            {item.name}
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='px-4'>
                        <h3 className='text-lg font-semibold mb-6 font-ebGaramond'>Recent News</h3>
                        <ul className='flex flex-col gap-y-4 text-white/60'>
                            {
                                news.map((item, index) => index <= 3 && (
                                    <li key={index}>
                                        <Link to={`/news/${item.id}`} className='flex items-center gap-x-4'>
                                            <img src={`${process.env.REACT_APP_API_LINK}/images/${item.image}`} alt="" className='size-12 object-cover object-center' />
                                            <div className='max-w-[400px]'>
                                                <div className='flex items-center text-gray-500 uppercase text-xs font-medium'>
                                                    <p className='tracking-wider'>{item.category.name}</p>
                                                    <p className='mx-1'>-</p>
                                                    <p>{new Date(item.createdAt).toDateString()}</p>
                                                </div>
                                                <h5 className='font-ebGaramond'>{item.name}</h5>
                                            </div>
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className='bg-neutral-900 text-white/60 py-8 px-8 font-ebGaramond'>
                <div className='container flex sm:flex-row flex-col justify-between items-center gap-y-4'>
                    <div>
                        <h4 className='flex gap-x-1 items-center text-lg'><CgCopyright /> Copyright NewsApp. All Rights Reserved
                        </h4>
                        <h5 className='sm:text-start text-center'>Create By <span className='text-white'>Aqil Farros Al Ghonim</span></h5>
                    </div>
                    <div>
                        <ul className='flex gap-x-4 items-center text-xl text-white'>
                            <li className='hover:bg-neutral-700 rounded-full p-2'>
                                <CgMail />
                            </li>
                            <li className='hover:bg-neutral-700 rounded-full p-2'>
                                <SiLinkedin />
                            </li>
                            <li className='hover:bg-neutral-700 rounded-full p-2'>
                                <FiInstagram />
                            </li>
                            <li className='hover:bg-neutral-700 rounded-full p-2'>
                                <PiGithubLogo />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
