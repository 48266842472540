import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading, NewsAside, UserLayout } from '../class'
import { GetCategoryByUser, GetNewsById } from '../libs/api'

const NewsPage = () => {
    const [loading, setLoading] = useState(true)
    const [category, setCategory] = useState([])
    const [news, setNews] = useState([])
    const { id } = useParams()
    const navigation = useNavigate()

    const fetchData = async () => {
        try {
            setNews(await GetNewsById(id))
            setCategory(await GetCategoryByUser())
            setLoading(false)
        } catch (error) {
            navigation('/')
        }
    }

    useEffect(() => {
        fetchData()
    }, [loading, id])

    return (
        loading
            ?
            <Loading />
            :
            <UserLayout>
                <div className="container mx-auto mb-12">
                    <div className='flex justify-between items-center text-neutral-900 pb-2 mb-4 border-b-4 border-neutral-900'>
                        <h1 className='sm:text-3xl text-xl font-medium tracking-wider font-ebGaramond'>{news.name}</h1>
                    </div>

                    <div className='flex'>
                        <div className='lg:w-[70%] w-full mx-4'>
                            <div className='w-[90%] mx-auto'>
                                <img src={`${process.env.REACT_APP_API_LINK}/images/${news.image}`} alt="" className='' />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: news.content }} className='ck-content content text-justify font-ebGaramond'></div>
                        </div>
                        <NewsAside news={news.category} category={category} />
                    </div>
                </div>
            </UserLayout>
    )
}

export default NewsPage
