import React, { useState } from 'react'
import { IoNewspaperOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import ErrorBox from './ErrorBox'
import { UserLayout } from '../class'

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const navigate = useNavigate()

    const Auth = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_LINK}/login`, {
                email: email,
                password: password,
            })

            navigate('/')
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            }
        }
    }

    return (
        <UserLayout>
            <div className='container w-full flex justify-center items-center mx-auto mb-12'>
                <div className='bg-slate-50 rounded shadow-xl border'>
                    <div className='flex justify-center items-center gap-x-2 bg-gradient-to-br from-neutral-800 via-neutral-900 to-neutral-950 px-5 py-1 rounded-t text-white text-xl font-medium'>
                        <IoNewspaperOutline className='' />
                        NewsApp
                    </div>
                    <div className='px-5 py-4'>
                        <h1 className='text-neutral-900 text-3xl text-center my-1 font-medium tracking-wide'>Login</h1>
                        <ErrorBox message={message} />

                        <form onSubmit={Auth}>
                            <div className='flex flex-col gap-y-4'>
                                <div className='flex flex-col'>
                                    <label htmlFor="email" className='mb-1 text-sm font-medium text-gray-900'>Email</label>
                                    <input type="text" id='email' placeholder='example@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)}
                                        className='sm:w-96 w-60 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="password" className='mb-1 text-sm font-medium text-gray-900'>Password</label>
                                    <input type="password" id='password' placeholder='********' value={password} onChange={(e) => setPassword(e.target.value)}
                                        className='sm:w-96 w-60 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500' />
                                </div>
                                <div className='flex items-center justify-between'>
                                    <Link to='/signup' className='text-blue-400 text-sm hover:underline'>Haven't have an account?</Link>
                                    <button type="submit" className='text-white bg-neutral-900 
                                focus:ring-1 focus:outline-none focus:ring-neutral-900
                                font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 shadow-md hover:shadow-xl transition-all duration-300'>Login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}

export default Login
