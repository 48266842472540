import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { AdminHeader, AdminLayout, ErrorBox, Loading } from '../../../class/index'
import { StoreCategory, VerifyAdmin } from '../../../libs/api'
import { useNavigate } from 'react-router-dom'

const CreateCategory = () => {
    const [formData, setFormData] = useState({
        name: '',
        image: ''
    })
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const handleChange = (event) => {
        const { value, name } = event.target

        setFormData({ ...formData, [name]: value })
        console.log(formData);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file })
        console.log(formData);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const response = await StoreCategory(formData)
            if (response == 'success') {
                navigate("/admin/category")
            } else {
                setMessage(response)
            }
        } catch (error) {
            console.log(error.response.data);
            setMessage(error.response.data.message)
        }
    }

    const fetchData = async () => {
        try {
            const response = await VerifyAdmin()
            if (response == "not admin") navigate('/')
            setLoading(false)
        } catch (error) {
            navigate('/')
        }
    }

    useEffect(() => {
        fetchData()
    }, [loading])

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={"Create Category"} />

                    <div className='mt-4'>
                        <form onSubmit={handleSubmit} encType='multipart/form-data'>
                            <ErrorBox message={message} />
                            <div className='flex flex-col gap-y-4'>
                                <div className='flex flex-col'>
                                    <label htmlFor="name" className='mb-1 text-sm font-medium text-gray-900'>Name</label>
                                    <input type="text" id='name' placeholder='Categoy Name' name='name' value={formData.name} onChange={handleChange}
                                        className='p-2 text-gray-900 border border-gray-300 rounded-lg 
                                bg-gray-50 text-sm focus:ring-gray-900 focus:border-gray-900' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="image" className='mb-1 text-sm font-medium text-gray-900'>Image</label>
                                    <input type="file" id='image' name='image' onChange={handleFileChange}
                                        className='p-2 text-gray-900 border border-gray-300 rounded-lg duration-300 transition-all
                                bg-gray-50 text-sm focus:ring-gray-900 focus:border-gray-900
                                file:mr-5 file:px-3 file:border-[0px]
                                file:text-sm file:font-medium file:rounded-l-lg
                                file:bg-stone-50 file:text-gray-900
                                hover:file:cursor-pointer hover:file:bg-white'
                                    />
                                </div>
                                <div className='flex items-center justify-end'>
                                    <button type="submit" className='text-white bg-neutral-900 
                                focus:ring-1 focus:outline-none focus:ring-neutral-900
                                font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 shadow-md hover:shadow-xl transition-all duration-300'>Create</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default CreateCategory
