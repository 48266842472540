import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DestroyCategory } from '../../../libs/api'

const CardCategory = ({ id, name, slug, image }) => {
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await DestroyCategory(id)

        if (response == 'success') {
            window.location.reload()
        } else {

        }
    }

    return (
        <div className='flex flex-col justify-between border rounded-lg w-72'>
            <div className='bg-zinc-50 rounded-t-lg overflow-hidden border-b'>
                <img src={`${process.env.REACT_APP_API_LINK}/images/${image}`} alt="" className='w-auto h-32 mx-auto' />
            </div>
            <div className='py-2 px-2'>
                <p>Name: <span>{name}</span></p>
                <p>Slug: <span>{slug}</span></p>
            </div>
            <div className='flex justify-end gap-x-4 px-2 pb-2'>
                <Link to={`/admin/category/${id}`} className='bg-yellow-500 text-white text-sm px-2 py-1 font-medium
                text-center rounded-md shadow-md hover:shadow-md hover:bg-yellow-600 transition-all duration-300'>Edit</Link>
                <form onSubmit={handleSubmit}>
                    <button className='bg-red-600 text-white text-sm px-2 py-1 font-medium
                text-center rounded-md shadow-md hover:shadow-md hover:bg-red-700 transition-all duration-300'>Delete</button>
                </form>
            </div>
        </div>
    )
}

export default CardCategory
