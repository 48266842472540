import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { IoNewspaperOutline } from 'react-icons/io5'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { HiBars3BottomLeft, HiMiniBars3 } from "react-icons/hi2";
import { GetCategoryByUser } from '../libs/api';

const Navbar = () => {
    const navigate = useNavigate()

    const [isAuth, setIsAuth] = useState(false)
    const [user, setUser] = useState("")
    const [isToggle, setIsToggle] = useState(false)
    const [category, setCategory] = useState([])

    const location = useLocation()

    const Auth = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_LINK}/auth`)

        if (response.status === 201) {
            setIsAuth(true)
            setUser(response)
        } else {
            setIsAuth(false)
        }
    }

    const fetchData = async () => {
        setCategory(await GetCategoryByUser())
    }

    useEffect(() => {
        Auth()
        fetchData()
    }, [])

    const Logout = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_LINK}/logout`)
            window.location.reload()
            navigate('/')
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <nav className={`fixed top-0 left-0 w-full ${isToggle ? '' : 'h-[52px]'} bg-gradient-to-br bg-neutral-50 shadow-md border-b overflow-hidden z-20`}>
            <div className="grid grid-cols-1 md:grid-cols-3 mx-auto px-4 py-2">
                <div className='flex justify-between items-center gap-x-20 mr-0 md:mr-auto my-auto'>
                    <Link to='/' className='flex justify-center items-center gap-x-2 text-3xl font-semibold'>
                        <IoNewspaperOutline />
                        NewsApp
                    </Link>
                    {
                        location.pathname === '/admin/*' || location.pathname === '/admin'
                            ?
                            <HiBars3BottomLeft className='text-4xl cursor-pointer' />
                            :
                            ''
                    }
                    <HiMiniBars3 className='text-4xl cursor-pointer md:hidden block' onClick={() => setIsToggle((prev) => !prev)} />
                </div>
                <div className='hidden md:flex gap-x-4 my-auto mx-auto'>
                    {
                        category.map((item, index) => index <= 2 && (
                            <Link key={index} to={`/category/${item.id}`} className='hover:text-neutral-600'>{item.name}</Link>
                        ))
                    }

                </div>
                <div className='hidden md:flex items-center gap-x-4 font-semibold my-auto ms-auto'>
                    {
                        isAuth
                            ?
                            <div className='flex items-center gap-x-4'>
                                {
                                    user?.data.role == 'admin'
                                        ?
                                        <Link to='/admin' className='hover:bg-neutral-200 text-sm px-2 py-1 text-center rounded-md transition-all duration-300'>Dashboard</Link>
                                        :
                                        ''
                                }
                                <button onClick={Logout} className='bg-neutral-900 text-white text-sm px-2 py-1 text-center rounded-md shadow-md hover:shadow-xl transition-all duration-300'>Log Out</button>
                            </div>
                            :
                            <div className='flex items-center gap-x-4'>
                                <Link to='/login' className='hover:bg-neutral-200 text-sm px-2 py-1 text-center rounded-md transition-all duration-300'>Log in</Link>
                                <Link to='/signup' className='bg-neutral-900 text-white text-sm px-2 py-1 text-center rounded-md shadow-md hover:shadow-xl transition-all duration-300'>Sign Up</Link>
                            </div>
                    }
                </div>
                <div className='md:hidden'>
                    <div className='flex flex-col items-end gap-y-2 font-semibold my-auto mx-auto'>
                        {
                            category.map((item, index) => index <= 2 && (
                                <Link key={index} to={`/category/${item.id}`} className='hover:text-neutral-600'>{item.name}</Link>
                            ))
                        }
                    </div>
                    <div className='flex justify-end gap-x-4 font-semibold my-2'>
                        {
                            isAuth
                                ?
                                <div className='flex items-center gap-x-4'>
                                    {
                                        user?.data.role == 'admin'
                                            ?
                                            <Link to='/admin' className='hover:bg-neutral-200 text-sm px-2 py-1 text-center rounded-md transition-all duration-300'>Dashboard</Link>
                                            :
                                            ''
                                    }
                                    <button onClick={Logout} className='bg-neutral-900 text-white text-sm px-2 py-1 text-center rounded-md shadow-md hover:shadow-xl transition-all duration-300'>Log Out</button>
                                </div>
                                :
                                <div className='flex items-center gap-x-4'>
                                    <Link to='/login' className='hover:bg-neutral-200 text-sm px-2 py-1 text-center rounded-md transition-all duration-300'>Log in</Link>
                                    <Link to='/signup' className='bg-neutral-900 text-white text-sm px-2 py-1 text-center rounded-md shadow-md hover:shadow-xl transition-all duration-300'>Sign Up</Link>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
