import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AdminHeader, AdminLayout, Loading } from '../../../class'
import { GetNewsById, VerifyAdmin } from '../../../libs/api'

const DetailNews = () => {
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const response = await VerifyAdmin()
            setNews(await GetNewsById(id))
            setLoading(false)
        } catch (error) {
            navigate("/")
        }
    }

    useEffect(() => {
        fetchData()
    }, [loading])

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={`Detail news of ${news.name}`} />

                    <div>
                        <div className='w-full h-[80vh] mt-8'>
                            <img src={`${process.env.REACT_APP_API_LINK}/images/${news.image}`} alt="" className='w-auto h-[80vh] rounded-lg mx-auto' />
                        </div>
                        <div className='py-8'>
                            <h1 className='text-3xl font-medium uppercase text-center pb-2 border-b-4'>{news.name}</h1>
                            <h3 className='mt-4'>Category: <span className='font-semibold'>{news.category.name}</span></h3>
                            <h3>Created by: <span className='font-semibold'>{news.user.name}</span></h3>
                            <div className='content' dangerouslySetInnerHTML={{ __html: news.content }}></div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default DetailNews
