import React, { useEffect, useState } from 'react'
import { AdminHeader, AdminLayout, Loading, TableNews } from '../../../class'
import { Link, useNavigate } from 'react-router-dom'
import { IoNewspaperOutline } from 'react-icons/io5'
import { GetNews, VerifyAdmin } from '../../../libs/api'

const News = () => {
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            setNews(await GetNews())
            const response = await VerifyAdmin()
            if (response == "not admin") navigate('/')
            setLoading(false)
        } catch (error) {
            navigate('/')
        }
    }

    useEffect(() => {
        fetchData()
    }, [loading])

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={'News'} />

                    <div className='flex justify-between items-center mt-2'>
                        <div className='flex items-center gap-x-2'>
                            <IoNewspaperOutline className="text-4xl" />
                            <h2 className='font-medium text-xl'>{news ? news.length : 0} News Have Been Created</h2>
                        </div>
                        <div>
                            <Link to='/admin/news/create' className='bg-neutral-900 text-white text-sm px-2 py-1 font-medium
                    text-center rounded-md shadow-md hover:shadow-md transition-all duration-300'>
                                Add News
                            </Link>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No.
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        news
                                            ?
                                            news.map((item, index) => (
                                                <TableNews key={index} id={item.id} name={item.name} slug={item.slug} i={index + 1} />
                                            ))
                                            :
                                            'o'
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default News
