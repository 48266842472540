import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <Navbar />
            <div className='mt-20 px-4'>
                {children}
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Layout
