import axios from "axios"

export const GetNews = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/news`)

    return response.data
}

export const GetNewsByUser = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/user/news`)

    return response.data
}

export const GetNewsById = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/news/${id}`)

    return response.data
}

export const StoreNews = async (formData) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_LINK}/news`, formData, { headers: { "Content-Type": "multipart/form-data" } })

        return "success"
    } catch (error) {
        return error.response.data.message
    }
}

export const UpdateNews = async (id, formData, image) => {
    try {
        if (image instanceof File) {
            await axios.put(`${process.env.REACT_APP_API_LINK}/news-image/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        } else {
            await axios.put(`${process.env.REACT_APP_API_LINK}/news/${id}`, formData)
        }

        return 'success'
    } catch (error) {
        return error.response.data.message
    }
}

export const DestroyNews = async (id) => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_LINK}/news/${id}`)

        return 'success'
    } catch (error) {
        return error
    }
}