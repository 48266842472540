import React from 'react'
import { Link } from 'react-router-dom'

const NewsAside = ({ news, category }) => {
    return (
        <div className='lg:block hidden w-[30%]'>
            <div className='flex justify-between items-center text-neutral-900 pb-2 mb-4 border-b-2 border-neutral-900'>
                <h1 className='sm:text-3xl text-xl font-medium tracking-wider font-ebGaramond'>More from {news.name}</h1>
            </div>

            <div>
                {
                    news.news.map((item, index) => (
                        <Link to={`/news/${item.id}`} key={index} className="inline-block w-full py-4 my-2 border-b border-neutral-900">
                            <p>{item.name.substring(0, 70) + '...'}</p>
                            <p className='text-gray-500 text-xs uppercase font-semibold'>{new Date(item.createdAt).toDateString()}</p>
                            <p className='text-xs text-gray-500'>Writer: <span className='font-semibold'>{item.user.name}</span></p>
                        </Link>
                    ))
                }
            </div>

            <div className='flex justify-between items-center text-neutral-900 pb-2 mb-4 border-b-2 border-neutral-900 mt-8'>
                <h1 className='sm:text-3xl text-xl font-medium tracking-wider font-ebGaramond'>More Category</h1>
            </div>

            <div className='flex flex-wrap gap-x-3 gap-y-3'>
                {
                    category.map((item, index) => (
                        <Link key={index} to={`/category/${item.id}`} className='bg-neutral-300 py-px px-2 transition-all duration-300 hover:bg-neutral-400'>{item.name}</Link>
                    ))
                }
            </div>
        </div>
    )
}

export default NewsAside
