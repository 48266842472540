import React from 'react'
import { Link } from 'react-router-dom'

const BoxCategory = ({ category, news }) => {
    return (
        <Link to={`/news/${news.id}`} className='flex lg:flex-row flex-col gap-y-4 max:w-[1200px] w-[95%] pb-4 border-b mb-12 mx-auto border-neutral-900'>
            <div className='lg:w-[50%] h-[350px] px-4 mx-auto'>
                <img src={`${process.env.REACT_APP_API_LINK}/images/${news.image}`} alt="" className='w-full h-full object-cover object-center' />
            </div>
            <div className='flex flex-col justify-around h-[350px] px-4'>
                <div className='flex items-center text-gray-500 uppercase text-sm 2xl:text-base font-semibold'>
                    <p>{category.name}</p>
                    <p className='mx-2'>-</p>
                    <p>{new Date(news.createdAt).toDateString()}</p>
                </div>
                <h1 className='xl:text-2xl text-xl font-semibold my-2 font-ebGaramond text-neutral-900'>{news.name}</h1>
                <p className='text-sm font-ebGaramond' dangerouslySetInnerHTML={{ __html: news.content.substring(0, 250) + '...' }}></p>
                <div className=''>
                    <p className='text-gray-500'>Writer: <span className='font-semibold'>{news.user.name}</span></p>
                </div>
            </div>
        </Link>
    )
}

export default BoxCategory
