import React, { useEffect, useState } from 'react'
import Loading from '../../Loading'
import { AdminHeader, AdminLayout, ErrorBox } from '../../../class'
import TextInput from '../../TextInput'
import { GetCategory, GetNewsById, GetNewsBySlug, GetUsers, UpdateNews } from '../../../libs/api'
import { useNavigate, useParams } from 'react-router-dom'

const EditNews = () => {
    const [news, setNews] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        content: "",
        image: "",
        userId: "",
        categoryId: ""
    })
    const [user, setUser] = useState([])
    const [category, setCategory] = useState([])
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { value, name } = e.target

        setFormData({ ...formData, [name]: value })
        console.log(formData);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        setFormData({ ...formData, image: file })
    }

    const handleCKEditor = (event, editor) => {
        const data = editor.getData()
        setFormData(prevFormData => ({ ...prevFormData, content: data }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await UpdateNews(news.id, formData, formData.image)

        if (response == 'success') {
            navigate('/admin/news')
        } else {
            setMessage(response)
        }
    }

    const fetchData = async () => {
        try {
            setUser(await GetUsers())
            setCategory(await GetCategory())
            setNews(await GetNewsById(id))
            setFormData({ ...formData, name: news.name, content: news.content, categoryId: news.categoryId, userId: news.userId })
            console.log(formData);
            setLoading(false)
        } catch (error) {
            navigate("/")
        }
    }

    useEffect(() => {
        fetchData()
    }, [loading])

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={`Edit News`} />

                    <div className='mt-4'>
                        <form onSubmit={handleSubmit}>
                            <ErrorBox message={message} />
                            <div className='flex flex-col gap-y-4'>
                                <div className='flex flex-col'>
                                    <label htmlFor="name" className='mb-1 text-sm font-medium text-gray-900'>Title</label>
                                    <input type="text" id='name' placeholder='News Title' name='name' defaultValue={formData.name} onChange={handleChange}
                                        className='p-2 text-gray-900 border border-gray-300 rounded-lg 
                                bg-gray-50 text-sm focus:ring-gray-900 focus:border-gray-900' />
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="userId" className="block mb-1 text-sm font-medium text-gray-900">Select a writer</label>
                                    <select id="userId" name='userId' defaultValue={formData.userId} onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full p-2">
                                        {
                                            user.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="categoryId" className="block mb-1 text-sm font-medium text-gray-900">Select a category</label>
                                    <select id="categoryId" name='categoryId' defaultValue={formData.categoryId} onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-900 focus:border-gray-900 block w-full p-2">
                                        {
                                            category.map((item) => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className='flex flex-col'>
                                    <label htmlFor="image" className='mb-1 text-sm font-medium text-gray-900'>Image</label>
                                    <input type="file" id='image' name='image' onChange={handleFileChange}
                                        className='p-2 text-gray-900 border border-gray-300 rounded-lg duration-300 transition-all
                                bg-gray-50 text-sm focus:ring-gray-900 focus:border-gray-900
                                file:mr-5 file:px-3 file:border-[0px]
                                file:text-sm file:font-medium file:rounded-l-lg
                                file:bg-stone-50 file:text-gray-900
                                hover:file:cursor-pointer hover:file:bg-white'
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor="content" className='mb-1 text-sm font-medium text-gray-900'>Content</label>
                                    <TextInput data={formData.content} handle={handleCKEditor} />
                                </div>
                                <div className='flex items-center justify-end'>
                                    <button type="submit" className='text-white bg-neutral-900 
                                focus:ring-1 focus:outline-none focus:ring-neutral-900
                                font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 shadow-md hover:shadow-xl transition-all duration-300'>Create</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default EditNews
