import React, { useEffect, useState } from 'react'
import { MdOutlineCategory } from 'react-icons/md'
import CardCategory from './CardCategory'
import { Link, useNavigate } from 'react-router-dom'
import { GetCategory, VerifyAdmin } from '../../../libs/api'
import { AdminHeader, AdminLayout, Loading } from '../../../class'

const Category = () => {
    const [category, setCategory] = useState([])
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            setCategory(await GetCategory())
            const response = await VerifyAdmin()
            if (response == "not admin") navigate('/')
            setLoading(false)
        } catch (error) {
            navigate('/')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={'Category'} />

                    <div className='flex justify-between items-center mt-2'>
                        <div className='flex items-center gap-x-2'>
                            <MdOutlineCategory className='text-4xl' />
                            <h2 className='font-medium text-xl'>{category ? category.length : 0} Category Have Been Created</h2>
                        </div>
                        <div>
                            <Link to='/admin/category/create' className='bg-neutral-900 text-white text-sm px-2 py-1 font-medium
                    text-center rounded-md shadow-md hover:shadow-md transition-all duration-300'>
                                Add Category
                            </Link>
                        </div>
                    </div>
                    <div className='flex justify-center flex-wrap gap-x-8 gap-y-8 mt-4'>
                        {
                            category
                                ?
                                category.map((item, index) => (
                                    <CardCategory key={index} id={item.id} name={item.name} slug={item.slug} image={item.image} />
                                ))
                                :
                                ""
                        }
                    </div>
                </div>
            </AdminLayout>
    )
}

export default Category
