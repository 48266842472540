import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Login, SignUp, AdminDashboard, AdminCategory, CreateCategory, DetailCategory, AdminNews, DetailNews, AdminImage, AdminUser, CreateNews, EditNews, Welcome, CategoryPage, NewsPage } from './class/index'
import Dashboard from './components/Admin/Dashboard';


function App() {
  return (
    <div>
      <Router>
        <Routes >
          <Route path='/' element={<Welcome />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/category/:id' element={<CategoryPage />} />
          <Route path='/news/:id' element={<NewsPage />} />
          <Route path='/admin'>
            <Route path='/admin' element={<AdminDashboard />} />
            <Route path='category'>
              <Route path='' element={<AdminCategory />} />
              <Route path='create' element={<CreateCategory />} />
              <Route path=':id' element={<DetailCategory />} />
            </Route>
            <Route path='news'>
              <Route path='' element={<AdminNews />} />
              <Route path=':id' element={<DetailNews />} />
              <Route path='create' element={<CreateNews />} />
              <Route path='edit/:id' element={<EditNews />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
