import React, { useEffect, useState } from 'react'
import { GoPeople, GoPerson } from 'react-icons/go'
import { MdOutlineCategory } from 'react-icons/md'
import { IoNewspaperOutline } from 'react-icons/io5'
import { PiImageLight } from 'react-icons/pi'
import { Loading, AdminHeader, AdminLayout } from '../../class'
import { useNavigate } from 'react-router-dom'
import { GetCategory, GetNews, GetUsers, VerifyAdmin } from '../../libs/api'

const Dashboard = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState({})
    const [allUser, setAllUser] = useState([])
    const [category, setCategory] = useState([])
    const [news, setNews] = useState([])
    const [loading, setLoading] = useState(true)

    const fetchData = async () => {
        try {
            setUser(await VerifyAdmin())
            setAllUser(await GetUsers())
            setCategory(await GetCategory())
            setNews(await GetNews())
            setLoading(false)
        } catch (error) {
            navigate('/')
        }
    }

    useEffect(() => {
        fetchData()
    }, [loading])

    return (
        loading
            ?
            <Loading />
            :
            <AdminLayout>
                <div>
                    <AdminHeader title={"Dashboard"} />
                    <div className='grid grid-cols-4 w-full'>
                        <div className="col-span-4 w-full flex items-center gap-x-6 my-3 py-2 px-3 bg-zinc-200 rounded-lg">
                            <GoPerson className='text-9xl rounded-full bg-white' />
                            <div>
                                <h1 className='text-3xl tracking-wider font-semibold'>Welcome Back</h1>
                                <h1 className='text-xl font-medium'>{user.name}</h1>
                            </div>
                        </div>
                        <div className='my-3 mr-3 py-2 px-3 bg-zinc-200 rounded-lg'>
                            <h1 className='text-xl text-neutral-700 font-semibold ml-2 mb-2 tracking-wide'>User</h1>
                            <div className='flex items-center gap-x-4'>
                                <div className='bg-white rounded-full'>
                                    <GoPeople className='text-7xl p-3' />
                                </div>
                                <p className='text-4xl font-semibold'>{allUser ? allUser.length : 0}</p>
                            </div>
                        </div>
                        <div className='my-3 mx-3 py-2 px-3 bg-zinc-200 rounded-lg'>
                            <h1 className='text-xl text-neutral-700 font-semibold ml-2 mb-2 tracking-wide'>Category</h1>
                            <div className='flex items-center gap-x-4'>
                                <div className='bg-white rounded-full'>
                                    <MdOutlineCategory className='text-7xl p-3' />
                                </div>
                                <p className='text-4xl font-semibold'>{category ? category.length : 0}</p>
                            </div>
                        </div>
                        <div className='my-3 mx-3 py-2 px-3 bg-zinc-200 rounded-lg'>
                            <h1 className='text-xl text-neutral-700 font-semibold ml-2 mb-2 tracking-wide'>News</h1>
                            <div className='flex items-center gap-x-4'>
                                <div className='bg-white rounded-full'>
                                    <IoNewspaperOutline className='text-7xl p-3' />
                                </div>
                                <p className='text-4xl font-semibold'>{news ? news.length : 0}</p>
                            </div>
                        </div>
                        <div className='my-3 ml-3 py-2 px-3 bg-zinc-200 rounded-lg'>
                            <h1 className='text-xl text-neutral-700 font-semibold ml-2 mb-2 tracking-wide'>Image</h1>
                            <div className='flex items-center gap-x-4'>
                                <div className='bg-white rounded-full'>
                                    <PiImageLight className='text-7xl p-3' />
                                </div>
                                <p className='text-4xl font-semibold'>145</p>
                            </div>
                        </div>
                    </div>
                </div>
            </AdminLayout>
    )
}

export default Dashboard
