import axios from "axios"

export const GetCategory = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/category`)

    return response.data
}

export const GetCategoryByUser = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/user/category`)

    return response.data
}

export const GetCategoryByUserAndId = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/user/category/${id}`)

    return response.data
}

export const GetCategoryById = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/category/${id}`)

    return response.data
}

export const StoreCategory = async (formData) => {
    try {
        if (formData.image instanceof File) {
            await axios.post(`${process.env.REACT_APP_API_LINK}/category`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        } else {
            await axios.post(`${process.env.REACT_APP_API_LINK}/category`, formData)
        }
        return "success"
    } catch (error) {
        return (error.response.data.message);
    }
}

export const updateCategory = async (id, formData, name, image) => {
    try {
        if (image instanceof File) {
            await axios.put(`${process.env.REACT_APP_API_LINK}/category-image/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        }
        else {
            await axios.put(`${process.env.REACT_APP_API_LINK}/category/${id}`, { name: name })
        }

        return "success"
    } catch (error) {
        return (error.response.data.message);
    }
}

export const DestroyCategory = async (id) => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_LINK}/category/${id}`)
        return "success"
    } catch (error) {
        return (error.response.data.message);
    }
}