import React from 'react'
import { Link } from 'react-router-dom'
import { DestroyNews } from '../../../libs/api'

const TableNews = ({ id, name, i }) => {
    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await DestroyNews(id)

        if (response == 'success') {
            window.location.reload()
        } else { }
    }

    return (
        <tr className="odd:bg-white even:bg-gray-50">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {i}
            </th>
            <td className="px-6 py-4">
                {name}
            </td>
            <td className="flex gap-x-4 px-6 py-4">
                <Link to={`/admin/news/${id}`} className="font-medium text-blue-600 hover:underline">Show</Link>
                <Link to={`/admin/news/edit/${id}`} className="font-medium text-yellow-600 hover:underline">Edit</Link>
                <form onSubmit={handleSubmit}>
                    <button type='submit' className="font-medium text-red-600 hover:underline">Delete</button>
                </form>
            </td>
        </tr>
    )
}

export default TableNews
