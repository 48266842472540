import axios from "axios"

export const GetUsers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/users`)

    return response.data
}

export const VerifyAdmin = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_LINK}/me`)

    if (response.data.role == 'admin') {
        return 'admin'
    } else {
        return 'not admin'
    }
}