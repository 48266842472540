import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MdOutlineDashboard, MdOutlineCategory } from "react-icons/md";
import { GoPeople } from "react-icons/go";
import { IoNewspaperOutline } from "react-icons/io5";
import { PiImageLight } from "react-icons/pi";

const Sidebar = () => {
    const aside = useRef()

    const location = useLocation()

    return (
        <aside ref={aside}
        className='mt-[52.8px] flex flex-col gap-y-2 bg-zinc-200 h-[calc(100vh_-_52.8px)] shadow-lg px-5 py-2 border-r'>
            <Link to='/admin' className={`${location.pathname === '/admin' ? 'bg-white' : ''} 
            flex items-center gap-x-1 text-lg font-medium pl-4 pr-24 py-2 rounded-md border-2 border-zinc-100 transition-all duration-300 hover:bg-white`}>
                <MdOutlineDashboard />
                Dashboard
            </Link>
            <Link to='/admin/user' className={`${location.pathname === '/admin/user' ? 'bg-white' : ''} 
            flex items-center gap-x-1 text-lg font-medium pl-4 pr-24 py-2 rounded-md border-2 border-zinc-100 transition-all duration-300 hover:bg-white`}>
                <GoPeople />
                Users
            </Link>
            <Link to='/admin/category' className={`${location.pathname === '/admin/category' ? 'bg-white' : ''} 
            flex items-center gap-x-1 text-lg font-medium pl-4 pr-24 py-2 rounded-md border-2 border-zinc-100 transition-all duration-300 hover:bg-white`}>
                <MdOutlineCategory />
                Category
            </Link>
            <Link to='/admin/news' className={`${location.pathname === '/admin/news' ? 'bg-white' : ''} 
            flex items-center gap-x-1 text-lg font-medium pl-4 pr-24 py-2 rounded-md border-2 border-zinc-100 transition-all duration-300 hover:bg-white`}>
                <IoNewspaperOutline />
                News
            </Link>
            <Link to='/admin/image' className={`${location.pathname === '/admin/image' ? 'bg-white' : ''} 
            flex items-center gap-x-1 text-lg font-medium pl-4 pr-24 py-2 rounded-md border-2 border-zinc-100 transition-all duration-300 hover:bg-white`}>
                <PiImageLight />
                Image
            </Link>
        </aside>
    )
}

export default Sidebar
