import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Box = ({ category, news }) => {
    const [randomBigNews, setRandomBigNews] = useState([])
    const [randomNews, setRandomNews] = useState([])

    useEffect(() => {
        setRandomBigNews(news?.sort(() => 0.5 - Math.random()).slice(0, 2))
        setRandomNews(news?.sort(() => 0.5 - Math.random()).slice(0, 3))
    }, [])

    return (
        <div className='container mx-auto px-2 mb-20'>
            <div className='flex justify-between items-center text-neutral-900 pb-2 mb-4 border-b-4 border-neutral-900'>
                <h1 className='sm:text-5xl text-3xl font-medium tracking-wider font-ebGaramond'>{category.name}</h1>
                <Link to={`/category/${category.id}`} className='text-xs pb-2 border-b-2 border-neutral-900 font-semibold uppercase'>SEE ALL {category.name}</Link>
            </div>

            <div className='grid grid-cols-6'>
                <Link to={`/news/${randomBigNews[0]?.id}`} className='flex md:flex-row flex-col lg:col-span-4 col-span-6 row-span-3 p-4'>
                    <img src={`${process.env.REACT_APP_API_LINK}/images/${randomBigNews[0]?.image}`} alt=""
                        className='md:w-[65%] h-[306px] object-cover object-center my-auto' />
                    <div className='relative md:w-[35%] px-4 md:py-0 py-8'>
                        <div className='flex items-center text-gray-500 uppercase text-sm 2xl:text-base font-semibold'>
                            <p>{category.name}</p>
                            <p className='mx-2'>-</p>
                            <p>{new Date(randomBigNews[0]?.createdAt).toDateString()}</p>
                        </div>
                        <h1 className='xl:text-2xl text-xl font-semibold my-2 font-ebGaramond text-neutral-900'>{randomBigNews[0]?.name.substring(0, 70) + '...'}</h1>
                        <p className='text-sm font-ebGaramond' dangerouslySetInnerHTML={{ __html: randomBigNews[0]?.content.substring(0, 250) + '...' }}></p>
                        <div className='absolute bottom-0'>
                            <p className='text-gray-500'>Writer: <span className='font-semibold'>{randomBigNews[0]?.user.name}</span></p>
                        </div>
                    </div>
                </Link>
                {
                    randomNews.map((item, index) => (
                        <Link to={`/news/${item.id}`} key={index} className="lg:col-span-2 col-span-6 border-b-2 p-4">
                            <div className='flex items-center text-gray-500 text-sm uppercase font-semibold'>
                                <p>{category.name}</p>
                                <p className='mx-2'>-</p>
                                <p>{new Date(item?.createdAt).toDateString()}</p>
                            </div>
                            <h1 className='text-lg font-semibold my-px font-ebGaramond text-neutral-900'>{item?.name.substring(0, 70) + '...'}</h1>
                            <div>
                                <p className='text-sm text-gray-500'>Writer: <span className='font-semibold'>{item?.user.name}</span></p>
                            </div>
                        </Link>
                    ))
                }
                <Link to={`/news/${randomNews[0]?.id}`} className='lg:col-span-2 col-span-6 row-span-2 border-b-2 p-4'>
                    <div className=''>
                        <div className='flex items-center text-gray-500 text-sm uppercase font-semibold'>
                            <p>{category.name}</p>
                            <p className='mx-2'>-</p>
                            <p>{new Date(randomNews[0]?.createdAt).toDateString()}</p>
                        </div>
                        <h1 className='text-lg font-semibold mt-2 font-ebGaramond text-neutral-900'>{randomNews[0]?.name.substring(0, 70) + '...'}</h1>
                        <p className='text-sm mt-6 font-ebGaramond' dangerouslySetInnerHTML={{ __html: randomNews[0]?.content.substring(0, 220) + '...' }}></p>
                        <div>
                            <p className='text-sm text-gray-500 mt-4'>Writer: <span className='font-semibold'>{randomNews[0]?.user.name}</span></p>
                        </div>
                    </div>
                </Link>
                <Link to={`/news/${randomBigNews[1]?.id}`} className="flex md:flex-row flex-col lg:col-span-4 col-span-6 row-span-3 p-4">
                    <img src={`${process.env.REACT_APP_API_LINK}/images/${randomBigNews[1]?.image}`} alt=""
                        className='md:w-[65%] h-[306px] object-cover object-center my-auto' />
                    <div className='relative md:w-[35%] px-4 md:py-0 py-8'>
                        <div className='flex items-center text-gray-500 uppercase text-sm 2xl:text-base font-semibold'>
                            <p>{category.name}</p>
                            <p className='mx-2'>-</p>
                            <p>{new Date(randomBigNews[1]?.createdAt).toDateString()}</p>
                        </div>
                        <h1 className='xl:text-2xl text-xl font-semibold my-2 font-ebGaramond text-neutral-900'>{randomBigNews[1]?.name.substring(0, 70) + '...'}</h1>
                        <p className='text-sm font-ebGaramond' dangerouslySetInnerHTML={{ __html: randomBigNews[1]?.content.substring(0, 220) + '...' }}></p>
                        <div className='absolute bottom-0'>
                            <p className='text-gray-500'>Writer: <span className='font-semibold'>{randomBigNews[1]?.user.name}</span></p>
                        </div>
                    </div>
                </Link>
                <Link to={`/news/${randomNews[1]?.id}`} className='lg:col-span-2 col-span-6 border-b-2 p-4'>
                    <div className=''>
                        <div className='flex items-center text-gray-500 text-sm uppercase font-semibold'>
                            <p>{category.name}</p>
                            <p className='mx-2'>-</p>
                            <p>{new Date(randomNews[1]?.createdAt).toDateString()}</p>
                        </div>
                        <h1 className='text-lg font-semibold my-2 font-ebGaramond text-neutral-900'>{randomNews[1]?.name.substring(0, 70) + '...'}</h1>
                        <div>
                            <p className='text-sm text-gray-500'>Writer: <span className='font-semibold'>{randomNews[1]?.user.name}</span></p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Box
