import React, { useEffect, useRef, useState } from 'react'
import { GoDotFill } from 'react-icons/go'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { GetNews } from '../libs/api'
import { Link } from 'react-router-dom'

const Carousel = ({ news }) => {
    const [randomNews, setRandomNews] = useState([])
    const [isSliderEnable, setIsSliderEnable] = useState(true)
    const slider = useRef()
    const i = 3
    const y = [1, 2, 4]

    const SliderLeft = (element) => {
        if (isSliderEnable) {
            setIsSliderEnable(false)

            if (element.scrollLeft === 0) element.scrollLeft = element.offsetWidth * (i - 1)
            else element.scrollLeft -= element.offsetWidth

            setTimeout(() => {
                setIsSliderEnable(true)
            }, 1500)
        }
    }

    const SliderRight = (element) => {
        if (isSliderEnable) {
            setIsSliderEnable(false)

            if (element.scrollLeft === element.offsetWidth * (i - 1)) element.scrollLeft = 0
            else slider.current.scrollLeft += element.offsetWidth

            setTimeout(() => {
                setIsSliderEnable(true)
            }, 1000)
        }
    }

    const handleDot = (element, index) => {
        if (isSliderEnable) {
            setIsSliderEnable(false)
            element.scrollLeft = index * element.offsetWidth

            setTimeout(() => {
                setIsSliderEnable(true)
            }, 1500)
        }
    }

    useEffect(() => {
        setRandomNews(news.sort(() => 0.5 - Math.random()).slice(0, 3))
    }, [])

    return (
        <div className='relative 2xl:w-[1200px] xl:w-[1100px] lg:w-[900px] md:w-[700px] sm:w-[600px] w-[300px] h-[300px] mx-auto mb-4'>
            <div className='flex w-full absolute top-1/2 -translate-y-1/2 justify-between items-center z-10'>
                <div className='bg-black/40 py-2 rounded-r-lg cursor-pointer transition-all duration-300 hover:bg-black/80 hover:shadow-xl'>
                    <IoIosArrowBack className='text-5xl text-white stroke-black stroke-[4px]' onClick={() => SliderLeft(slider.current)} />
                </div>
                <div className='bg-black/40 py-2 rounded-l-lg cursor-pointer transition-all duration-300 hover:bg-black/80 hover:shadow-xl'>
                    <IoIosArrowForward className='text-5xl text-white stroke-black stroke-[4px]' onClick={() => SliderRight(slider.current)} />
                </div>
            </div>
            <div className='flex w-full overflow-auto no-scrollbar scroll-smooth transition-all duration-300' ref={slider}>
                {
                    randomNews.map((item, index) => index < 3 && (
                        <Link to={`/news/${item.id}`} key={index} className='relative min-w-full'>
                            <img key={index} src={`${process.env.REACT_APP_API_LINK}/images/${item.image}`} alt=""
                                className='min-w-full h-[300px] object-cover object-center rounded-md' />
                            <h1 className='absolute bottom-6 left-4 md:text-2xl sm:text-lg font-ebGaramond text-white'>{item.name}</h1>
                        </Link>
                    ))
                }
            </div>
            <div className='flex gap-x-1 absolute left-1/2 -translate-x-1/2 bottom-2 text-2xl text-white/40'>
                {y.map((e, index) => {
                    return (<GoDotFill key={index} className={`cursor-pointer transition-all duration-300 
                        ${slider.current?.scrollLeft >= index * slider.current?.offsetWidth
                            &&
                            slider.current?.scrollLeft < (index + 1) * slider.current?.offsetWidth
                            ?
                            'text-white'
                            :
                            ''
                        } 
                        hover:text-white`} onClick={() => handleDot(slider.current, index)} />)
                })}
            </div>
        </div>
    )
}

export default Carousel
