import React from 'react'
import Navbar from '../Navbar'
import Sidebar from './Sidebar'
import './admin.css'

const layout = ({ children }) => {
    return (
        <div>
            <Navbar />
            <React.Fragment>
                <div className='flex'>
                    <Sidebar />
                    <div className='mt-[52.8px] px-4 w-full h-[calc(100vh_-_52.8px)] overflow-auto'>
                        {children}
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

export default layout
