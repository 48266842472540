import React from 'react'
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react'
import { Autoformat, Base64UploadAdapter, BlockQuote, Bold, ClassicEditor, Context, ContextWatchdog, Essentials, Heading, Indent, IndentBlock, Italic, Link, List, Mention, Paragraph, PasteFromOffice, Table, TableColumnResize, TableToolbar, TextTransformation, Underline, PastePlainText } from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'

const TextInput = ({ data, handle }) => {
    const plugins = [
        Autoformat,
        BlockQuote,
        Bold,
        Base64UploadAdapter,
        Essentials,
        Heading,
        Indent,
        IndentBlock,
        Italic,
        Link,
        List,
        Mention,
        Paragraph,
        PasteFromOffice,
        PastePlainText,
        Table,
        TableColumnResize,
        TableToolbar,
        TextTransformation,
        Underline,
    ]

    const toolbar = [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        '|',
        'link',
        'insertTable',
        'blockQuote',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
    ]

    const heading = {
        options: [
            {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'ck-heading_paragraph',
            },
            {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'ck-heading_heading1',
            },
            {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'ck-heading_heading2',
            },
            {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: 'ck-heading_heading3',
            },
            {
                model: 'heading4',
                view: 'h4',
                title: 'Heading 4',
                class: 'ck-heading_heading4',
            },
        ],
    }

    const link = {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
    }

    const table = {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    }

    return (
        <div>
            <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        plugins: plugins,
                        toolbar: toolbar,
                        heading: heading,
                        link: link,
                        table: table,
                    }}
                    data={data}
                    onChange={handle}
                />
            </CKEditorContext>
        </div>
    )
}

export default TextInput
